import "isomorphic-fetch";

class ServerUtil {
	constructor(urlRoot) {
		this.urlRoot = urlRoot;
	}

	setToken(token) {
		this.token = token;
	}

	setPendingCallback(cb) {
		this.pendingCallback = cb;
	}

	call(endpoint, args, options) {
		options = Object.assign({}, options, {
			method: 'POST'
		});

		// Allow {{pending-indicator}} to render a progress bar
		if (this.pendingCallback)
			this.pendingCallback(true);

		const token = this.token; //this.get('feathers.authToken'); // required to authenticate request to the endpoint
		const urlBase = this.urlRoot + endpoint; //config.feathers.socketUrl + endpoint;
		const httpData = token ? Object.assign( {}, args,  { token }) : args;

		let fetchUrl = urlBase;
		if(options.method === 'GET') {
			const fields = [];
			Object.keys(httpData).forEach(key => {
				const val = httpData[key];
				if(Array.isArray(val)) {
					val.forEach((v,x) => {
						fields.push(encodeURIComponent(key+'[' + x + ']')+'='+encodeURIComponent(v));
					});
				} else
				if(val && typeof(val) === 'object') {
					Object.keys(val).forEach(vk => {
						fields.push(encodeURIComponent(key+'['+vk+']')+'='+encodeURIComponent(JSON.stringify(val[vk])));
					});
				} else {
					fields.push(encodeURIComponent(key)+'='+encodeURIComponent(httpData[key]));
				}
			});

			if(fields.length)
				fetchUrl += '?' + fields.join('&');
		}

		const authHeader = {};
		if(token)
			authHeader.Authorization = token;

		return fetch(fetchUrl, {
			method: options.method,
			// data:   httpData,
			mode: "cors", // no-cors, cors, *same-origin
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			credentials: "same-origin", // include, same-origin, *omit
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				...authHeader
				// "Content-Type": "application/x-www-form-urlencoded",
			},
			redirect: "follow", // manual, *follow, error
			referrerPolicy: "no-referrer", // no-referrer, *client
			body: options.method === 'GET' ? undefined : JSON.stringify(httpData), // body data type must match "Content-Type" header
		}).then(res => {
			//console.log(res.json());
			return res.json();
		}).then(json => {
			// Allow {{pending-indicator}} to render a progress bar
			if (this.pendingCallback)
				this.pendingCallback(false);

			if(json && json.error) {
				console.error("[service:middleware.call]", { endpoint, httpData, options, token }, " failure: ", json.error ); // eslint-disable-line
			}
			return json;
		}).catch(err => {
			// Allow {{pending-indicator}} to render a progress bar
			if (this.pendingCallback)
				this.pendingCallback(false);

			// Errors from Stripe are propogated in success() as res.error,
			// so we should never get this error() handler here
			console.error("[service:middleware.call]", { endpoint, httpData, options, token }, " failure: ", err && err.statusText ? { reason: err.statusText, error: err } : err ); // eslint-disable-line no-console
			// console.re.error("[custom-adapter.customServiceCall]", { modelName, callMethod }, " failure: ", err && err.statusText ? { reason: err.statusText, error: err } : err ); // eslint-disable-line no-console
			//alert("Problem replaying events");

			return err;
		});

	}
}

export default ServerUtil;