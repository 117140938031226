import React from 'react'

import Layout            from '../components/layout'
import DirHeroImage      from '../components/images/dir-hero-image';
import HeroWidget        from '../components/hero-widget';
import Footer            from '../components/footer'
import Directory         from '../components/directory'

import styles from './directory.module.scss';

const Page = () => (
	<Layout page="about">
		<div className={styles.page}>

			<HeroWidget image={DirHeroImage}>
				<h1>Directory</h1>
			</HeroWidget>

			<section>
				<Directory/>
			</section>

			<Footer/>
		</div>
	</Layout>
)

export default Page;
