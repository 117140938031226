import React from 'react'
import $ from 'jquery'; // for accessing $.ajax
import produce from 'immer';
import { InputGroup, InputGroupAddon, InputGroupText, Input, Button, ButtonGroup } from 'reactstrap';
import LoadingSpinner from '../loading-spinner';
import styles from './directory.module.scss';
import DirEditWidget from './dir-edit-widget';

import ServerUtil from './server-util';
import { instanceOf } from 'prop-types';

const windowGlobal = typeof window !== 'undefined' && window;

// const API_SERVER = true ? 
const API_SERVER = process.env.NODE_ENV === 'production' ? 
	'https://phcdirsrv.jbiconsult.com' : 
	'http://localhost:3000';


const rpc = new ServerUtil(API_SERVER);

export default class Navbar extends React.Component {
	constructor(props) {
		super(props);

		if(windowGlobal.localStorage) {
			const token   = windowGlobal.localStorage.getItem('auth:token');
			const isAdmin = windowGlobal.localStorage.getItem('auth:isAdmin') === "true";

			this.state = {
				authed: token ? true : false,
				isAdmin,
				token,
				error: null,
				list: [],
				loading: false
			};

			console.log("<Directory>[constructor] ", this.state);

			rpc.setPendingCallback(flag => this.patchState(d => d.loading = flag ));
			rpc.setToken(token);
		} else {
			this.state = {
				authed: false,
				isAdmin: false,
				token: null,
				error: null,
				list: [],
				loading: false
			};
		}

	}

	componentDidMount() {
		this.mounted = true;
		if(this.state.token)
			this.getDirectoryList();
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	// call(url, data={}, method='GET') {
	// 	url = API_SERVER + url;
	// 	if(this.state.token)
	// 		data.token = this.state.token;

	// 	this.patchState(d => {
	// 		d.loading = true;
	// 	})

	// 	console.log("<Directory>[call]", { url, data, method });
	// 	return new Promise((resolve, reject) => {
	// 		$.ajax({
	// 			method,
	// 			url,
	// 			data,
	// 			success: res => {
	// 				console.log("<Directory>[call.success]", { res });
	// 				this.patchState(d => {
	// 					d.loading = false;
	// 				});
	// 				// if(res.error)
	// 				// 	// reject(res.error);
	// 				// 	return this.logoutAction() && res.error;
	// 				// resolve(res);
	// 				resolve(res);
	// 			},
	// 			error: err => {
	// 				console.log("<Directory>[call.error]", { err });
	// 				this.patchState(d => {
	// 					d.loading = false;
	// 				});
	// 				// reject(err);
	// 				if(err.status === 0)
	// 					err = "Cannot communicate with server";
	// 				resolve({ error: err.toString() });
	// 			}
	// 		});
	// 	});
	// }

	patchState(callback) {
		if(!this.mounted)
			return;

		this.setState(produce(draft => {
			callback(draft);
		}));
	}

	async logoutAction() {
		rpc.setToken(null);

		this.patchState(draft => {
			draft.authed = false;
			draft.isAdmin = false;
			draft.token = null;
			draft.error = null;
			draft.list = [];
			draft.loading = false;
		});

		windowGlobal.localStorage.setItem('auth:isAdmin', 'false');
		windowGlobal.localStorage.setItem('auth:token',   "");
			
	}

	async loginAction() {
		const { password } = this.state;
		const res = await rpc.call('/dir/token', { password });

		if(!res.error && !res.token) {
			res.error = "Invalid response from server - no token";
		}

		if(res.error) {

			console.warn("[loginAction] err=", res.error);
			windowGlobal.localStorage.setItem('auth:token', null);

			rpc.setToken(null);

			this.patchState(draft => {
				draft.authed = false;
				draft.error = res.error;
				draft.token = null;
				draft.list = [];
			});

			console.warn("[loginAction] final state=", this.state);

			// throw res.error;
			return;
		}

		// if(!res.token)
		// 	throw new Error("Invalid response from server - no token");

		console.log("[loginAction] ", {res});
		// return;

		windowGlobal.localStorage.setItem('auth:isAdmin', res.isAdmin ? 'true' : 'false');
		windowGlobal.localStorage.setItem('auth:token',   res.token);

		// For use in other calls
		rpc.setToken(res.token);
		
		this.patchState(draft => {
			draft.authed = true;
			draft.isAdmin = res.isAdmin;
			draft.token = res.token;
			draft.error = null;
		});

		this.getDirectoryList();
	}

	showEntry(res) {

		setTimeout( () => {
			const $el = $('[data-id=' + res.id + "]");
			if ($el[0])
				$el[0].scrollIntoView();
		}, 50);
	}

	async createPanelAction() {
		this.patchState(d => {
			d.editing    = true;
			d.createMode = true;
			d.model      = {
				lastName: '',
				firstName: '',
				cellPhone: '',
				homePhone: '',
				address: '',
				city: '',
				state: '',
				zip: '',
				spouseName: '',
				spouseCellPhone: '',
				childrenList: [],
			};
		});
	}

	async editPanelAction(model) {
		// console.log("[editPanelAction] ", { model })

		this.patchState(d => {
			d.editing    = true;
			d.createMode = false;
			d.model      = model;
		});
	}


	async getDirectoryList(forceCacheBust) {
		const list = await rpc.call('/dir/list');
		if(list.error) {
			return this.patchState(d => {
				d.error = list.error;
			});
		}

		list.forEach(entry => {
			if (entry.photoId)
				entry.photoUrl = API_SERVER + "/dir/photo/" + entry.photoId + (forceCacheBust ? '?cb=' + Date.now() : '')
			if (entry.childrenList)
				entry.childrenList = JSON.parse(entry.childrenList);
		});
		
		this.patchState(draft => {
			draft.list = list;
		});
	}

	inputChanged(event) {
		const target = event.target,
			value    = target.value,
			name     = target.name;

		this.patchState(draft => draft[name] = value);
	}

	async editSavedAction(ref) {
		this.patchState(d => {
			d.editing = false;
		});

		if(Array.isArray(this.state.model.childrenList))
			this.state.model.childrenList = JSON.stringify(this.state.model.childrenList);
		// console.log("[editSavedAction] ref=", ref);

		const url = this.state.createMode ?
			'/dir/create' :
			'/dir/update/' + this.state.model.id;
			
		try {
			const res = await rpc.call(url, { model: this.state.model });
			if(res.error)
				throw res.error;

			console.log("[editSavedAction] res=", res);
		} catch(err) {
			this.patchState(draft => {
				draft.error = err;
			});
		}
	
		await this.getDirectoryList(true); // forceCacheBust
		
		this.showEntry(ref);
	}

	async editDeleteAction() {
		if(this.state.createMode)
			return;

		this.patchState(d => {
			d.editing = false;
		});

		const url = '/dir/delete/' + this.state.model.id;
			
		const res = await rpc.call(url);
		if(res.error)
			throw res.error;

		console.log("[editDeleteAction] res=", res);
		
		await this.getDirectoryList();
	}

	editCancelAction() {
		this.patchState(d => {
			d.editing = false;
		});

		this.showEntry(this.state.model);
	}

	render() {
		// console.log("<Directory>[render]", this.state)
		
		return (
		
		<div className={styles.dirWrap}>
			{this.state.loading && 
				<LoadingSpinner title="Loading..."/>
			}
			{this.state.editing &&
				<DirEditWidget
					model={this.state.model}
					createMode={this.state.createMode}
					onSaved={this.editSavedAction.bind(this)}
					onCancel={this.editCancelAction.bind(this)}
					onDelete={this.editDeleteAction.bind(this)}
				/>
			}
			{!this.state.authed && 
				<div className={styles.loginPanel}>
					<InputGroup>
						<InputGroupAddon addonType="prepend">Password</InputGroupAddon>
						<Input type="password" name="password" onBlur={this.inputChanged.bind(this)}/>
					</InputGroup>

					<Button onClick={this.loginAction.bind(this)} color="success">
						Login
					</Button>

				</div>
			}
			{this.state.error &&
				<h1 className={styles.error}>
					Error: {this.state.error}
				</h1>
			}
			{this.state.authed && <>
				<div className={styles.authHeader}>
					{
						this.state.isAdmin ? 
							<b>You're an administrator</b>
							:
							<b>You're logged in</b>
					}
					<ButtonGroup>
						<Button onClick={this.logoutAction.bind(this)} color="warning">Logout</Button>
						{this.state.isAdmin && 
							<Button onClick={this.createPanelAction.bind(this)} color="success">Add ...</Button>
						}
					</ButtonGroup>
				</div>
				<ul className={styles.dirList}>
					{this.state.list && this.state.list.map(entry =>
						<li key={entry.id}>

							{/* <a name={"dir"+entry.id} className="divider"><hr size=1/></a> */}
							<div className={styles.entryWrap}>
								<div className={styles.entryHeaderWrap}>
									<div className={styles.photoWrap}>
										{entry.photoUrl && <a href={entry.photoUrl} target='_blank'>
											<img src={entry.photoUrl}/>
										</a>}
									</div>
									<div className={styles.infoWrap}
										data-id={entry.id}
										data-admin={this.state.isAdmin ? "true":"false"}
										onClick={this.state.isAdmin ? this.editPanelAction.bind(this, entry) : null}>
										<div className={styles.familyName}>
											{entry.firstName + " "} 
											{entry.spouseName && "& " + entry.spouseName + " "}
											{entry.lastName}
										</div>

										{/* <address>{entry.address}</address> */}
									</div>
								</div>

								<table className={styles.infoTable}>
									<tbody>
										{entry.address && <tr>
											<td>Address</td>
											<td>{entry.address}</td>
										</tr>}
										{entry.homePhone && <tr>
											<td>Home Phone</td>
											<td>{entry.homePhone}</td>
										</tr>}
										{entry.cellPhone && <tr>
											<td>{entry.spouseName && entry.firstName+"'s"} Cell Phone</td>
											<td>{entry.cellPhone}</td>
										</tr>}
										{entry.spouseName && entry.spouseCellPhone && <tr>
											<td>{entry.spouseName}'s Cell Phone</td>
											<td>{entry.spouseCellPhone}</td>
										</tr>}
										{entry.email && <tr>
											<td>{entry.spouseName && entry.firstName+"'s"} Email</td>
											<td>{entry.email}</td>
										</tr>}
										{entry.spouseName && entry.spouseEmail && <tr>
											<td>{entry.spouseName}'s Email</td>
											<td>{entry.spouseEmail}</td>
										</tr>}
										{entry.birthday && <tr>
											<td>{entry.spouseName && entry.firstName+"'s"} Birthday</td>
											<td>{entry.birthday}</td>
										</tr>}
										{entry.spouseName && entry.spouseBirthday && <tr>
											<td>{entry.spouseName}'s Birthday</td>
											<td>{entry.spouseBirthday}</td>
										</tr>}
										{entry.spouseName && entry.anniversary && <tr>
											<td>Anniversary</td>
											<td>{entry.anniversary}</td>
										</tr>}
										{Array.isArray(entry.childrenList) && 
											entry.childrenList.length  > 0 && <tr>
											<td className={styles.childrenCellLabel}>Children</td>
											<td>
												<table className={styles.childrenList}>
													<tbody>
														{entry.childrenList.map((kid, idx) => <tr key={idx}>
															<td>{kid.display}</td>
															<td>{kid.birthday}</td>
														</tr>)}
													</tbody>
												</table>
											</td>
										</tr>}
									</tbody>
								</table>
							</div>
						</li>
					)}
				</ul>

			</>}
		
		</div>
	)}
};



// export default Navbar;
