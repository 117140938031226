/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from 'react';
import {
	Button, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter,
	InputGroup, InputGroupAddon, InputGroupText, Input
} from 'reactstrap';
import produce from 'immer';
import styles from './dir-edit.module.scss';
import FileInput from 'react-simple-file-input';
 
var allowedFileTypes = ["image/png", "image/jpeg", "image/gif"];
 
function fileIsIncorrectFiletype(file){
  if (allowedFileTypes.indexOf(file.type) === -1) {
    return true;
  } else {
    return false;
  }
}


class ModalExample extends React.Component {
	constructor(props) {
		super(props);

		this.deleteAction = this.deleteAction.bind(this);
		this.closeAction = this.closeAction.bind(this);
		this.saveAction  = this.saveAction.bind(this);
		this.fieldChanged = this.fieldChanged.bind(this);

		this.model = this.props.model || {};
		console.log("[modal] model=", this.model);

		this.state = {
			photoUrl: (this.model || {}).photoUrl,
			childrenList: (this.model || {}).childrenList || [],
		};
	}

	closeAction() {
		this.props.onCancel();
	}

	saveAction() {
		this.model.childrenList = this.state.childrenList;
		this.props.onSaved(this.model);
	}

	deleteAction() {
		if (window.confirm("Are you sure?"))
			this.props.onDelete();
	}

	fieldChanged(event) {
		const target = event.target,
			value    = target.value,
			name     = target.name;

		if(this.model)
			this.model[name] = value;
	}

	photoChangeHandler(event) {
		// console.log("[photoChangeHandler]", event);
	}

	handleFileSelected(event, file){
		// console.log("[handleFileSelected]", {file: file, fileContents: event.target.result});

		this.setState(produce(draft => {
			draft.photoUrl = event.target.result;
		}));

		/// Will be sent to server and stored accordingly
		this.model.photoUrl = event.target.result;
	}

	clearPhoto() {
		// 1x1 white png from http://png-pixel.com/
		this.model.photoUrl = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=";

		this.setState(produce(draft => {
			draft.photoUrl = this.model.photoUrl;
		}));
	}

	addChild() {
		this.setState(produce(draft => {
			draft.childrenList.push({first: '', birthday: ''});
		}));
	}

	childChanged(child, event) {
		
		const target = event.target,
			value    = target.value,
			name     = target.name;

		if(child)
			child[name] = value;
	}

	render() {
		return (
			<Modal isOpen={true} toggle={this.closeAction} className={this.props.className} size="lg">
				<ModalHeader toggle={this.closeAction}>{this.props.createMode ? "Create" : "Edit"}</ModalHeader>
				<ModalBody>
					<div className={styles.field}>
						<label>First Name</label>
						<Input defaultValue={this.model.firstName} name="firstName" onBlur={this.fieldChanged}/>
					</div>

					<div className={styles.field}>
						<label>Last Name</label>
						<Input defaultValue={this.model.lastName} name="lastName" onBlur={this.fieldChanged}/>
					</div>

					<div className={styles.field}>
						<label>Photo</label>
						<div className={styles.photoBtnWrap}>
							<img src={this.state.photoUrl}/>

							<ButtonGroup>
								<Button color="warning">
									<label >
										<FileInput
											readAs='dataUrl'
											style={ { display: 'none' } }
											accept="image/*"
											onLoad={this.handleFileSelected.bind(this)}
										/>
							
										<span >
											Change photo
										</span>
							
									</label>
								</Button>

								<Button color="danger" onClick={this.clearPhoto.bind(this)}>
									Remove Photo
								</Button>
							</ButtonGroup>

						</div>
					</div>

					<div className={styles.field}>
						<label>Cell Phone</label>
						<Input defaultValue={this.model.cellPhone} name="cellPhone" onBlur={this.fieldChanged} type="tel"/>
					</div>

					<div className={styles.field}>
						<label>Home Phone</label>
						<Input defaultValue={this.model.homePhone} name="homePhone" onBlur={this.fieldChanged} type="tel"/>
					</div>

					<div className={styles.field}>
						<label>Address</label>
						<Input defaultValue={this.model.address} name="address" onBlur={this.fieldChanged}/>
					</div>

					<div className={styles.field}>
						<label>Email</label>
						<Input defaultValue={this.model.email} name="email" onBlur={this.fieldChanged} type="email"/>
					</div>

					<div className={styles.field}>
						<label>Spouse</label>
						<Input defaultValue={this.model.spouseName} name="spouseName" onBlur={this.fieldChanged}/>
					</div>

					<div className={styles.field}>
						<label>Spouse's Cell Phone</label>
						<Input defaultValue={this.model.spouseCellPhone} name="spouseCellPhone" onBlur={this.fieldChanged} type="tel"/>
					</div>

					<div className={styles.field}>
						<label>Spouse's Email</label>
						<Input defaultValue={this.model.spouseEmail} name="spouseEmail" onBlur={this.fieldChanged} type="email"/>
					</div>

					<div className={styles.field}>
						<label>Anniversary</label>
						<Input defaultValue={this.model.anniversary} name="anniversary" onBlur={this.fieldChanged} type="text"/>
					</div>

					<div className={styles.field}>
						<label>Children</label>
						<table className={"table table-bordered table-hover table-condensed "+ styles.childrenList}>
							<tbody>
								{(this.state.childrenList || []).map((kid, idx) => <tr key={idx}>
									<td>
										<Input defaultValue={kid.display} name="display" onBlur={this.childChanged.bind(this, kid)} type="text"/>
									</td>
									<td>
										<Input defaultValue={kid.birthday} name="birthday" onBlur={this.childChanged.bind(this, kid)} type="text"/>
									</td>
								</tr>)}
							</tbody>
						</table>
						<Button color="dangersuccess" onClick={this.addChild.bind(this)}>
							Add Another Child
						</Button>
					</div>

				</ModalBody>
				<ModalFooter className={styles.footer}>
					{!this.props.createMode && 
						<Button color="danger" onClick={this.deleteAction}>Delete</Button>
					}
					<ButtonGroup>
						<Button color="success" onClick={this.saveAction}>Save Changes</Button>{' '}
						<Button color="secondary" onClick={this.closeAction}>Cancel</Button>
					</ButtonGroup>
				</ModalFooter>
			</Modal>
			
		);
	}
}

export default ModalExample;